<template>
  <div class="checkQrcode">
    <div class="topWrap">项目公示信息</div>
    <h1>{{title}}</h1>
    <div class="time">创建时间：{{createTime}}</div>
    <div class="line"></div>
    <div class="mainBody" v-html="mainBody"></div>
  </div>
</template>

<style lang="less" scope>
.checkQrcode {
  width: 100vw;
  height: 100vh;
  
  .topWrap {
    background-color: #409EFF;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
  }
  h1 {
    text-align: center;
  }
  .time {
    margin-top: 5px;
    text-align: center;
  }
  .line {
    height: 1px;
    background-color: #999;
    margin: 20px 0;
  }
  .mainBody{
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>

<script>
export default {
  name: "checkQrcode",
  data() {
    return {
      title: null,
      mainBody: null,
      createTime: null,
      updateTime: null,
    };
  },
  mounted() {
    this.onGetDetail()
  },
  watch: {},
  methods: {
    onGetDetail: function () {
      var that = this;
      var data = {
        id: this.$route.params.id
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/qrcode/details",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.loading.close();
          this.title = res.data.title
          this.mainBody = this.escape2Html(res.data.mainBody)
          this.createTime = res.data.createTime
          this.updateTime = res.data.updateTime
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 解码
    escape2Html: function (str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
  },
};
</script>